.footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 8px 8px;
  font-family: "Rubik";
  background-color: #f8f8f8;
  border-top: 1px solid #e0e0e0;
  width: 100%;

  &__feedback {
    font-family: "Rubik";
    font-weight: 500;
    text-align: right;
    font-size: 12px;
    margin-right: 2px;
    cursor: pointer;

    &.title {
      display: flex;
      justify-content: center;
      font-weight: 600;
      color: #f57903;
    }
  }
}

@media screen and (min-width: 280px) and (max-width: 767px) {
  .footer {
    &__feedback {
      &.web {
        display: none;
      }
    }
    &__left-wrapper {
      display: flex;
      flex-direction: column;
      margin-left: 0;
      img {
        display: none;
      }
      &.copyright {
        color: grey;
        font-size: 10px;
        font-weight: 400;
      }
      &.tmdb {
        color: grey;
        font-size: 6px;
        font-weight: 400;
      }
    }
  
  }
}

@media screen and (min-width: 768px) {
  .footer {
    &__left-wrapper {
      margin-left: 0;
      display: flex;
      flex-direction: row;
      // align-items: center;

      img {
        width: 100px;
        height: 50px;
      }
      &.copyright {
        margin-top: 16px;
        margin-left: 10px;
        font-size: 14px;
      }
      &.tmdb {
        margin-left: 3px;
        margin-top: 20px;
        color: grey;
        font-size: 10px;
        font-weight: 400;
      }
    }
    &__feedback {
      font-size: 13px;
      &.mobile {
        display: none;
      }
    }
  }
}
