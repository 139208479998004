// Global styles
.page-wrapper {
    font-family: "Alata";
    background-color: #FFDFB1;
}

.detail-container {
    margin: 0 10%;
}

.header-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    .title-wrapper {
        display: flex;
        flex-direction: column;
        min-width: 80vw;
        max-width: 80vw;
        .title {
            font-weight: 500;
            color: #472A00;
            font-size: 24px;
            margin-right: 48px;
        }
    }
    .info-wrapper {
        margin-bottom: 8px;
        &__duration {
            font-size: 0.85rem;
        }
        color: #472A00;
    }
}

.genres {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    font-size: 0.85rem;
    font-weight: 500;
    font-family: "Rubik";
    align-items: center;
    margin-bottom: 8px;
    max-width: 100vw;
    div {
        margin-right: 8px;
        margin-bottom: 8px;
        padding: 4px 12px;
        background-color: #e2e1e1;
        border-radius: 8px;
        background-color: #ffb74d;
    }
}

@media screen and (max-width: 500px) {
    .genres {
        white-space: pre-wrap;
        font-size: 0.75rem;
    }
}

.awards {
    display: flex;
    font-size: 0.75rem;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
    div {
        margin-bottom: 8px;
        margin-right: 8px;
        padding: 4px 12px;
        background-color: #ff9500;
        color: white;
        border-radius: 8px;
    }
}

.overview {
    font-size: 0.85rem;
    font-weight: medium;
    font-family: "Rubik";
    margin: 12px 0;
    color: #472A00;
}

.rating {
    &__outer {
        display: flex;
        align-items: center;
        margin: 24px 0;
        color: #ff9500;
    }
    &__inner {
        display: flex;
        position: relative;
        height: 5px;
        background-color: #ece8e86e;
        border-radius: 5px;
        align-items: center;
        .filled-bar {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            height: 100%;
            background: linear-gradient( 90deg, rgba(255, 149, 0, 0.2) 0%, rgba(255, 149, 0, 1) 88%, rgba(255, 149, 0, 0.05) 100%);
            border-radius: 5px;
        }
        .percentage {
            // box-shadow: 0 0 8px rgba(12, 188, 35);
            // background: rgb(19, 250, 50);
            // background: radial-gradient(
            //   circle,
            //   rgba(12, 188, 35, 0.905) 0%,
            //   rgba(12, 188, 35, 0.26934523809523814) 100%
            // );
            position: absolute;
            right: 0;
            padding: 6px;
            border-radius: 50%;
            color: #ff9500;
            font-size: 12px;
            font-weight: 500;
            z-index: 1;
        }
    }
}

.imdb-lb-wrapper,
.rotten-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    margin: 16px 0;
}

.ratings {
    &__wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 180px;
        height: 50px;
        padding: 8px 12px;
        border: none;
        border-radius: 8px;
        cursor: pointer;
        &.imdb,
        &.rotten {
            margin-right: 8px;
        }
        a {
            text-decoration: none;
        }
    }
    &__logo,
    &__score {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-right: 16px;
        font-size: 12px;
        div {
            font-weight: 500;
        }
    }
    &__score {
        margin-right: 0;
    }
}

.render-rating {
    font-weight:400;
    font-family: "Rubik";
    color: #472A00;
    &__wrapper {
        display: flex;
        align-items: center;
        margin-bottom: 2px;
    }
    &__rating,
    &__no-reviews {
        font-size: 16px;
    }
    &__no-reviews {
        margin-top: 12px;
    }
    &__scale {
        font-size: 16px;
    }
    &__reviews {
        font-weight: 300;
        font-size: 12px;
    }
}

.avg-rating {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 500;
    div {
        color: #472A00;
        font-weight: 400;
        margin-left: 8px;
    }
}

.stars-wrapper {
    display: flex;
    align-items: center;
    margin-top: 8px;
    color: #e54d03;
    &__star {
        margin-left: 8px;
        font-weight: 700;
    }
}

.thumbs-wrapper {
    // &__mobile {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 24px 0;
    .thumbs {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        padding: 6px;
        margin-right: 24px;
        border-radius: 50%;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
        cursor: pointer;
        transition: background-color 0.3s, box-shadow 0.3s, transform 0.3s;
        &:hover {
            transform: scale(1.2);
        }
        // &.up {
        // }
        // &.down {
        // }
        // &.bookmark {
        //   color: rgb(241, 170, 40);
        // }
        &.seen {
            font-family: "Rubik";
            font-weight: 500;
            font-size: 11px;
        }
    }
    // }
}

// MOBILE  VIEW
@media screen and (min-width: 301px) and (max-width: 767px) {
    .detail-cover {
        &__web {
            display: none;
            img {
                display: none;
            }
        }
        &__mobile {
            width: -webkit-fill-available;
            margin: 0;
            margin-top: 65px;
            position: relative;
            overflow: hidden;
            img {
                width: 100%;
                height: 300px;
                object-fit: cover;
            }
        }
    }
    .img-trailer {
        &__web {
            display: none;
        }
        &__mobile {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            margin-top: 2.5rem;
            img {
                border-radius: 8px;
                width: 25%;
            }
            iframe {
                width: 100%;
                height: 250px;
                overflow: hidden;
                border: none;
            }
        }
    }
    .title-wrapper {
        margin-top: 5%;
        .title {
            display: flex;
            align-items: center;
            text-align: left;
            font-size: 1.25rem;
            margin-bottom: 8px;
            line-height: normal;
        }
        .info-wrapper {
            &__duration {
                display: flex;
                margin-bottom: 8px;
            }
        }
    }
    .icons {
        &__mobile {
            display: flex;
            flex-direction: row;
            align-items: center;
        }
        &__web {
            display: none;
        }
    }
    .ratings {
        &__header {
            &.web {
                display: none;
            }
        }
    }
    .star-rating {
        &__header {
            margin: 1rem 2rem 0;
            font-size: 16px;
            font-weight: 500;
        }
        &__mobile {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-bottom: 75px;
        }
        &__web {
            display: none;
        }
        &__total {
            color: #6e6e6e;
            margin-top: 8px;
        }
    }
    .info-wrapper {
        &__type {
            &.web {
                display: none;
            }
        }
    }
    // .crew-info__web {
    //   display: none;
    // }
    .poster-header {
        display: none;
    }
}

// DESKTOP VIEW
@media screen and (min-width: 768px) {
    .detail-cover {
        &__web {
            width: -webkit-fill-available;
            margin: 0;
            margin-top: 65px;
            position: relative;
            overflow: hidden;
            img {
                width: 100%;
                height: 300px;
                object-fit: cover;
            }
        }
        &__mobile {
            display: none;
            img {
                display: none;
            }
        }
    }
    .header-wrapper {
        .title-wrapper {
            margin: 16px;
            .title {
                font-size: 36px;
            }
        }
        .info-wrapper {
            &__type {
                &.web {
                    margin-right: 14px;
                    padding: 7px 12px;
                    border-radius: 5px;
                    font-weight: 500;
                    border: 2px solid #ffb54c;
                    font-size: 1rem;
                }
            }
            &__duration {
                font-size: 0.85rem;
            }
        }
    }
    .img-trailer {
        &__mobile {
            display: none;
        }
        &__web {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            margin-top: 65px;
            height: 350px;
            width: 100%;
            margin-bottom: 0;
            iframe {
                border-radius: 8px;
                overflow: hidden;
                border: none;
                width: 80%;
            }
        }
    }
    .ratings {
        &__header {
            &.web {
                margin-left: 1rem;
                margin-top: 1rem;
            }
        }
    }
    .avg-rating {
        &__mobile {
            display: none;
        }
    }
    .overview {
        font-size: 1rem;
    }
    .genres {
        &__mobile {
            display: none;
        }
        &__web {
            display: flex;
            flex-direction: row;
        }
    }
    .all-ratings-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    .imdb-lb-wrapper {
        margin-left: 16px;
        .ratings {
            &__wrapper {
                margin-right: 16px;
                &.imdb,
                &.letter {
                    padding: 26px;
                }
            }
        }
    }
    .icons {
        &__mobile {
            display: none;
        }
        &__web {
            display: flex;
            flex-direction: row;
            align-items: center;
        }
    }
    .thumbs-wrapper {
        &__mobile {
            display: none;
        }
    }
}