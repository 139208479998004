.form-wrapper.sign-up {
  height: 85vh;
}

.form {
  background: white;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 3px;
  border-style: solid;
    border-color: #FF9500;
  padding: 30px 36px;
}

.form.reset {
  padding: 30px 20px;
}

.form-title {
    color: #F57903;
  font-family: Rubik;
  font-size: 28px;
  line-height: 32px;
  text-align: left;
  margin-bottom: 6px;
  font-weight: 500;
}

.form-title.forgot-password {
  margin-bottom: 20px;
}

.form-title.reset {
  width: 90%;
  margin-bottom: 20px;
  margin-left: 26px;
}

.form-subtitle {
  margin-bottom: 18px;
    color: #9E9E9E;
  font-family: Rubik;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
}

.form-input-label {
  margin-bottom: 0px;
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
    color: rgba(0, 0, 0, 0.80);
  font-family: Rubik;
  font-size: 16px;
  font-weight: 500;
  line-height: 27.59765625px;
  text-align: left;
}

.form-input-label.reset {
  margin: 10px 18px;
  width: 90%;
  line-height: 20px;
}

.form-input {
  margin-bottom: 24px;
  padding: 2px 8px;
  width: 100%;
  height: 36px;
  background: rgba(238, 238, 238, 0);
    border: 1px solid #E0E0E0;
  border-radius: 8px;
  box-sizing: border-box;
  font-size: 16px;
  font-family: Rubik;
  font-weight: 400;
}

.form-input.forgot-password {
  margin-bottom: 26px;
}

.form-group {
  margin-bottom: 24px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: left;
    color: rgba(0, 0, 0, 0.80);
  font-family: Rubik;
  font-size: 18px;
  font-weight: 400;
  line-height: 27.59765625px;
}

.form-radio-group {
  display: flex;
}

.form-radio {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 20px;
}

.form-radio.age {
  margin-right: 12px;
}

.form-radio input[type="radio"] {
  appearance: none;
  border: 2px solid rgba(84, 84, 84, 1);
  border-radius: 70%;
  width: 18px;
  height: 18px;
  margin-right: 8px;
  transition: background-color 0.3s;
}

.form-radio.age input[type="radio"]{
  margin-right: 4px;
}

.form-radio input[type="radio"]:checked {
  background-color: black;
}

.form-radio span {
  font-size: 14px;
  color: black;
  text-wrap: nowrap;
}

.form-forgot-password-wrapper {
  display: flex;
  justify-content: center;
}

.form-forgot-password-button {
  width: 130px;
  height: 60px;
  background: rgba(189, 187, 187, 0);
  padding: 2px;
  font-family: Rubik;
  font-size: 12px;
  font-weight: 400;
  border-radius: 30px;
  border: none;
    color: rgba(0, 0, 0, 0.80);
  cursor: pointer;
  text-wrap: nowrap;
}

.button-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .button {
    width: 100%;
    height: 60px;
    background: rgb(189, 187, 187);
    padding: 2px;
    font-family: Rubik;
    font-size: 18px;
    font-weight: 500;
        background-color: #FFB54C;
    border-radius: 30px;
    border: none;
    cursor: pointer;
  }
}

.button-wrapper.checkmark {
  display: flex;
  justify-content: center;
}

.alt-account-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  bottom: 0;
  .black-text {
    margin-right: 6px;
        color: rgba(0, 0, 0, 0.80);
    font-family: Rubik;
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    text-wrap: nowrap;
    display: inline-block;
  }
  .black-text.resend {
    font-size: 14px;
    font-weight: 500;
  }
  .button-text {
    margin-left: 6px;
    background: rgba(189, 187, 187, 0);
    font-family: Rubik;
    font-size: 16px;
    font-weight: 500;
    border-radius: 30px;
    border: none;
        color: #E54D03;
    text-decoration: underline;
    display: inline-block;
    text-wrap: nowrap;
    cursor: pointer;
  }
  .button-text.resend {
    font-size: 14px;
        color: #FF9500;
  }
}

.enter-email {
  margin-bottom: 26px;
    color: #9E9E9E;
  font-family: Rubik;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
}

// MOBILE  VIEW
@media screen and (min-width: 280px) and (max-width: 767px) {
  .form-container {
    display: flex;
    justify-content: center;
    margin-top: 25%;
    height: 100vh;
    background-color: rgb(255 223 177);
  }
  .form-input-label {
    margin-left: 2px;
  }
  .form-input {
    margin-bottom: 18px;
  }
  .form-input.short {
    margin-bottom: 2px;
  }
}

// DESKTOP VIEW
@media screen and (min-width: 768px) {
  .form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: rgb(255 223 177);
  }
  .form-wrapper {
    width: 40vw;
    height: 90vh;
    padding: 1.5rem;
  }
  .form-wrapper.sign-up {
    height: 100vh;
  }
  .form {
    padding: 50px;
  }
  .form-title {
    margin-bottom: 10px;
    font-weight: 700;
  }
  .form-subtitle {
    margin-bottom: 24px;
  }
  .form-input-label {
    margin-bottom: 10px;
    font-size: 18px;
  }
  .form-input {
    margin-bottom: 20px;
    height: 40px;
        border: 2px solid #9E9E9E;
  }
  .form-input.short {
    margin-bottom: 10px;
  }
}