.view-more {
  color: #ffffff;
  font-family: "Rubik";
  font-weight: 500;
  display: flex;
  align-items: center;
  cursor: pointer;
}

// MOBILE  VIEW
@media screen and (min-width: 280px) and (max-width: 767px) {
  .carousel-header {
    font-size: 20px;
    color: #472A00;
    font-family: "Rubik";
    font-weight: 500;
    padding: 20px;
    padding-left: 9px;
    width: auto;
  }
  .view-more {
    font-size: 16px;
    color: #472A00;
    text-decoration: underline;
  }
}

// DESKTOP VIEW
@media screen and (min-width: 768px) {
  .carousel-header {
    font-size: 28px;
    color: #472A00;
    font-family: "Rubik";
    font-weight: 500;
    padding: 20px;
    padding-left: 20px;
    margin-right: 10px;
  }
  .view-more {
    font-size: 18px;
    color: #472A00;
    text-decoration: underline;
  }
}

// for bottom transparency of mobile listing cards
.mobile-card {
  position: relative;
  border-bottom: 1px solid transparent !important;
  mask-image: linear-gradient(#000, rgba(0, 0, 0, 0.9));
}

.mobile-card:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 85%,
    #ffdfb1 100%
  );
  height: 40%;
}
