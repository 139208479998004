// DESKTOP VIEW
// @media screen and (min-width: 768px) {

.carousel-card-wrapper {
  border: 1px solid #ccc;
  border-radius: 10px;
  position: relative;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  font-family: "Rubik";
  font-weight: 500;
  height: 385px;
  overflow: hidden; // Hide the overflowing content
}

.carousel-card {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 0.2s linear;
  transform: translate(-50%, -50%);
  z-index: 0;
}

.hover-card {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 0%;
  padding: 16px;
  padding-bottom: 0px;
  display: flex;
  flex-direction: column;
  opacity: 0;
  pointer-events: none;
  background-color: none;
  z-index: 1;
  transition: opacity 0.2s linear;
}

.hover-card.visible {
  opacity: 1;
  pointer-events: auto;
}

// .rating {
//   &__inner {
//     display: flex;
//     width: 100%;
//     height: 10px;
//     background-color: #ece8e86e;
//     border-radius: 5px;
//     align-items: center;
//     .filled-bar {
//       display: flex;
//       align-items: center;
//       width: 100%;
//       height: 100%;
//       background: linear-gradient(
//         90deg,
//         rgba(255, 149, 0, 0.2) 0%,
//         rgba(255, 149, 0, 1) 88%,
//         rgba(255, 149, 0, 0.05) 100%
//       );
//       border-radius: 5px;
//     }
//   }
// }

// }

// MOBILE  VIEW
@media screen and (min-width: 301px) and (max-width: 767px) {
  .carousel-card-wrapper {
    width: 100%;
    height: 200px;
  }

  .carousel-card-wrapper::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 10%;
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.5)
    );
    pointer-events: none; /* Make sure clicks go through the overlay */
  }

  .rating {
    &__inner {
      display: flex;
      position: relative;
      width: 100%;
      height: 5px;
      background-color: #ece8e86e;
      border-radius: 5px;
      align-items: center;
      .filled-bar {
        display: flex;
        align-items: center;
        // justify-content: flex-end;
        height: 100%;
        background: linear-gradient(
          90deg,
          rgba(255, 149, 0, 0.2) 0%,
          rgba(255, 149, 0, 1) 88%,
          rgba(255, 149, 0, 0.05) 100%
        );
        border-radius: 5px;
      }
    }
  }
}

@media screen and (min-width: 280px) and (max-width: 300px) {
  .carousel-card-wrapper {
    height: 155px;
  }
}
